<template>
    <v-row>
        <v-col cols="12">
            <v-alert dense type="warning" class="white--text">
                The call will be ended once the kiosk user ends the call and you will be redirected to homepage.
            </v-alert>
        </v-col>

        <v-col cols="12" align="center" justify="center" class="mt-5">
            <div class="video-panel" id="video-panel"></div>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    name: 'telepresence',
    data() {
        return {
            videoHeight: window.innerHeight - 200,
            videoWidth: window.innerWidth - 500,
            selectedGroup: ""
        }
    },
    computed: {
        ...mapGetters(['getMeetingUrl', 'userProfile', 'userGroups', 'allRegisteredUsers']),
        getUsers() {
            // this.allRegisteredUsers.forEach(user => {
            //     console.log(user.data())
            // })
            return this.allRegisteredUsers.filter(user => user.data().groups.includes(this.selectedGroup))
            //return this.allRegisteredUsers
        }
    },
    methods: {
        endCall() {
            this.$store.dispatch('endCall')
        },
        scaleJitsi(width, height) {
            document.getElementById(this.api.getIFrame().id).style.width =
                width + "px";
            document.getElementById(this.api.getIFrame().id).style.height =
                height + "px";
        },
        getColor(status) {
            if (status === "Unavailable") return "error";
            if (status === "Busy") return "amber";
            if (status === "Available") return "success";
        },
    },
    watch: {
        $route(to, from) {
            if (from.path === "/telepresence") {
                this.forceRerender();
            }
        },
        selectedGroup(newVal) {
            console.log('Selected Group: ', this.selectedGroup)
        }
    },
    mounted() {
        if (this.getMeetingUrl.meetingCode === undefined) {
            this.$router.push("/");
        } else {
            this.$store.commit("setUserCallStatus", true);
            const domain =
                "meet.arsconnect.com/" + this.getMeetingUrl.meetingCode;

            // const domain = "clara-connect1.arsconnect.com"
            const options = {
                roomName: this.getMeetingUrl.meetingCode,
                width: this.videoWidth,
                height: this.videoHeight,
                parentNode: document.querySelector("#video-panel"),
                interfaceConfigOverwrite: {
                    filmStripOnly: false,
                    TOOLBAR_BUTTONS: ['hangup', 'camera', 'microphone'],
                    TOOLBAR_ALWAYS_VISIBLE: false,
                    HIDE_INVITE_MORE_HEADER: true,
                    DISABLE_VIDEO_BACKGROUND: true,
                    DEFAULT_BACKGROUND: "#FFF",
                },
                userInfo: {
                    email: "",
                    displayName: this.userProfile.data().name,
                },
            };
            // let iframes = document.getElementsByTagName("iframe");
            // iframes[0].contentWindow.console.info = () => {};
            window.JitsiMeetExternalAPI =
                window.JitsiMeetExternalAPI || window.exports.JitsiMeetExternalAPI;
            this.api = new window.JitsiMeetExternalAPI(domain, options);

            this.api.addListener("participantLeft", () => {
                this.participants = this.api.getNumberOfParticipants();

                if (this.participants === 1) {

                    this.endCall()
                }
            });

            this.api.addListener("participantJoined", () => {

                this.participants = this.api.getNumberOfParticipants();

                // if (this.noOfParticipants >= 1) {
                //   clearTimeout(this.callEndTimeout);
                // }
            });

            this.api.addListener("readyToClose", () => {
                this.endCall()
            })
        }
    }
}
</script>